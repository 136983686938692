@function multiple-box-shadow ($n) {
  $value: '#{random(2000)}px #{random(20000)}px #FFF';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(20000)}px #{random(8000)}px #FFF';
  }
  @return unquote($value);

}

$shadows-small:  multiple-box-shadow(10000);
$shadows-medium: multiple-box-shadow(3000);
$shadows-big:    multiple-box-shadow(1500);


#stars {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
    animation: animStar 50s linear infinite;
    &:after {
      content: " ";
      position: relative;
      top: 2000px;
      width: 1px;
      height: 1px;
      background: transparent;
      box-shadow: $shadows-small;
    }
  }
  
  #stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
    animation: animStar 100s linear infinite;
    &:after {
      content: " ";
      position: relative;
      top: 2000px;
      width: 2px;
      height: 2px;
      background: transparent;
      box-shadow: $shadows-medium;
    }
  }
  
  #stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
    animation: animStar 150s linear infinite;
    &:after {
      content: "";
      position: relative;
      top: 2000px;
      width: 3px;
      height: 3px;
      background: transparent;
      box-shadow: $shadows-big;
    }
  }
  
  @keyframes animStar {
    from {
      transform: translateY(0px);
    }
  
    to {
      transform: translateY(-2000px);
    }
  }

  .background {
    overflow: hidden;
    background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
    height: 100vh;
}